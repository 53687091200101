<template>
  <div>
    <div class="back action" @click="$router.go(-1)">
      <b-icon-arrow-left-short class="icon" />
      Voltar para Lista
    </div>
    <!-- main content-detail -->
    <div class="site-container" v-if="item !== null">
      <div class="content-detail">

        <div class="image">
          <div v-if="item.photo !== null" class="photo-detail" :style="{'background-image': 'url(data:image/jpeg;base64,'+item.photo+')'}">
          <div v-if="item.photo === null" class="photo-detail" :style="{'background-image': 'url(data:image/jpeg;base64,'+noImage+')'}"></div>
          </div>
          <br>
          <div class="alert-time text-alert-time"><b-icon icon="exclamation-triangle" style="margin-top: 1em;" /> <b>Alerta:</b> <span>Tempo restante para alcançar a meta é de <b> {{item.remainingMonths === 0 ? 1 : item.remainingMonths}} {{item.remainingMonths > 1 ? 'meses' : 'mês'}}! </b></span> </div>
        </div>

        <div class="detail left margin-left">
          <div class="header">
            <div class="name">{{ item.name }}</div>
          </div>

          <div class="body ">
            <div class="left">
              <div class="item">Cidade:<br class="br-mobile"><span class="style-value-text">{{ item.city }}/{{ item.state }}</span></div>
              <div class="item">Previsão de Entrega:<br class="br-mobile"><span class="style-value-text">{{ formatDate(item.realeseDate) }}</span></div>
            </div>
            <div class="left col2">
              <div class="item item-total-investido">Construtora:<br class="br-mobile"><span class="style-value-text">{{ item.companyName }}</span></div>
              <div class="item item-total-investido">Total Investido:<br class="br-mobile"><span class="style-value-text total-sold">{{ formatCurrencyPrice(item.totalInvested) }}</span></div>
            </div>
            <div v-if="isInvestor" class="left col2">
              <div class="item" style="display: none;"><br><br><span></span></div>
            </div>
          </div>

          <div class="size_total margin-bottom-image">
            <div>
              <div class="left item">Status: <span class="style-value-text-mobile">{{ item.quotasAcquiredPercent }}% Captado</span></div>
              <div class="center item">Valor Captado: <span class="style-value-text-mobile">{{ formatCurrencyPrice(item.valueCaptured.toFixed(2)) }}</span></div>
              <div class="right item">Meta Mínima: <span class="style-value-text-mobile">{{ item.goal.toFixed(2) }} Cotas</span></div>
              <div style="clear: both" />
            </div>
            <div class="left size_total">
              <div id="progress_sold" class="progress">
                 <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${item.quotasAcquiredPercent}%;`"/>
            </div>
            </div>
          </div>
            <div class="buttons-mobile item margin-left " v-if="item.quotaAvailable">
              <div vif class="left button-actions">
                <b-button class="invest" @click="investNow(item.idImmobile)">Comprar mais cotas</b-button>
              </div>
              <div class="left button-actions">
                <b-button class="cancel" @click="$refs.cancellModal.show()" :disabled="existsRequest">Cancelar Investimento</b-button>
              </div>
              <div class="left button-actions">
                <a :href="talkSpecialist()" target="_blank"> 
                 <b-button @click="talkSpecialist" class="talk">Falar com Especialista</b-button>
                </a>
              </div>
            </div>
        </div>
      </div>

      <div style="clear: both" />

      <div class="site-item">
        <MoreInfo name="moreinfo" :project="item" :isInvestor="isInvestor" :investmentId="investmentId"/>
      </div>
    </div>
    <CancellationRequest ref="cancellModal" :investmentId="$route.params['id']"/>
  </div>
</template>

<script>

import { BButton, BProgress, BModal, BFormCheckbox, BIcon } from 'bootstrap-vue'
import Footer from './components/parts/footer/Footer.vue'
import ImageOpprotunity from './components/images/ImageOpportunity.vue'
import MoreInfo from './components/opportunity/MoreInfo.vue'
import Similar from './components/opportunity/Similar.vue'
import NewsletterCard from './components/NewsletterCard.vue'
import OpportunityService from '@/services/OpportunityService'
import formatter from '@/mixins/formatter'
import CancellationRequest from '../investor/CancellationRequest.vue'
import CancellationRequestsService from '@/services/Investment/CancellationRequestsService'
import TalkSpecialistConfigService from '@/services/Investment/TalkSpecialistConfigService'

export default {
  name: 'SiteOportunityDetail',

  components: {
    Footer,
    BButton,
    ImageOpprotunity,
    BProgress,
    MoreInfo,
    Similar,
    NewsletterCard,
    BModal,
    BFormCheckbox,
    BIcon,
    CancellationRequest
  },
    created() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    },
    destroyed() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    },
  mixins:[formatter],

  async mounted() {
    await this.getItem()
    await this.getRequescCancellExists()
    await this.getTaklSpecialistData()
  },

  data() {
    return {
      url: window.location.href,
      item: null,
      existsRequest: false,
      talkSpecialistData: {},
      investmentId: null
    }
  },

  methods: {
    back() {
      this.$router.push({ path: '/opportunity' })
    },

    async getRequescCancellExists(){
      this.existsRequest = (await CancellationRequestsService.existsRequest(this.$route.params['id'])).data.result
    },
    share() {
      navigator.clipboard.writeText(this.url)
      this.notifyCopy()
    },
    notifyCopy() {
      this.$bvToast.toast('Link copiado.', {
        autoHideDelay: 2000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
      })
    },
    async getTaklSpecialistData(){
      this.talkSpecialistData = (await TalkSpecialistConfigService.GetTaskSpecialistConfigForEdit()).data.result.taskSpecialistConfig
    },
    
     talkSpecialist(){
      this.talkSpecialistData.phone = this.talkSpecialistData.phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
      let msg = this.talkSpecialistData.msg
      while(msg.includes('\n')){
        msg = msg.replace('\n', '%0A')
      }
      if (this.talkSpecialistData.isAddUrl === true) {
        let url = this.url
        msg =`${msg}%0A ${url}`
      }
      return this.talkSpecialistData.phone !== null ? `https://wa.me/55${this.talkSpecialistData.phone}?text=${msg}` : ''
    },
    
    async getItem() {
      this.investmentId = this.$route.params['id']
      const a = await OpportunityService.getDetailsByInvestment(this.investmentId)
      const nn = this.$route.params['nameProfile']
      this.isInvestor = this.$route.params['nameProfile'] === 'investor'
      this.item = a.data.result
    },
    formatDate(dateString) {
      return formatter.formatDate(dateString)
    },
    investNow(){
      localStorage.setItem('immobileIdSelected', this.item.idImmobile)
      this.$router.push({ path: '/checkout' }) 
    }
  },
}
</script>

<style lang="scss" scoped>

.modal-queue h1 {
  text-align: center;
}
.modal-queue .modal-queue-footer {
  margin-top: 20px;
}

.valor span {
  font-size: 2.0em;
  font-weight: 500 !important;
  color: #A6ADBA;
}

.share {
  background-color: transparent !important;
  width: 100%;
  text-align: left;
  border:none;
  padding:0;
}
.action:hover,
.share:hover {
  color: orange !important;
  box-shadow: 0;
}

.action {
  cursor: pointer;
  transition: .3s;  
  color: black;
}

.icon {
  margin: 0px 5px 0px 0px;
}

.site-container {
  margin-top: -270px;
  margin-top: 5%;
  width: 100%;
  margin-left: 0;
}
.size_total {
  width: 100%;
}
.margin-left {
  margin-left: 30px;
}
.col2 {
  margin-left: 50px;
}
.left {
  float: left;
}
.center {
  float: left;
  margin-left: 20% !important;
}
.right {
  float: right;
}
.text-right {
  text-align: right;
}

.content-detail {
  align-items: center;
}

.content-detail .image {
  float:left;
}
.content-detail .image .photo-detail {
  width: 380px;
  height: 380px;
  border-radius: 12px;
  z-index: 1;
  border: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.style-value-text{
  color: #A6ADBA;
  font-weight: 400;
}

.content-detail .detail .header .name {
  color: black;
  font-size: 2.6rem;
  font-weight: 600;
}
.content-detail .detail .header .desc {
  color: #7C8391;
  font-size: 1.5rem;
  font-weight: 600;
}

  .alert-time {
    left: 0px;
    height: 40px;
    right: 0px;
    top: 0%;
    background: #FFEEEF;
    border-radius: 4px;
    text-align: center;
  }
  .text-alert-time {
    color: #EA5455;
  }
.content-detail .detail .header .back {
  font-size: 0.9rem;
}
.content-detail .detail .header .share {
  font-size: 0.9rem;
  position: absolute;
  bottom: 0px;
}

.content-detail .item {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.content-detail .detail .item {
  margin: 20px 0px 20px 0px;
}
.content-detail .invest {
  white-space: nowrap;
  background-color: orange !important;
  border: none;
  padding: 15px 40px 15px 40px;
}
.content-detail .talk {
  white-space: nowrap;
  border: none;
  background-color: white !important;
  color: black !important;
  padding: 15px 40px 15px 40px;
}
.content-detail .wait-queue {
  width: 100%;
  border: 1px solid #E9E9E9  !important;
}
.content-detail .detail .body {
  width: 100%;
  display: inline-block;
}
.content-detail .item span {
  font-weight: 100;
}
.total-sold{
  font-size: 2.2em !important;
  margin-top: 20% !important;
  font-weight: 400 !important;
  
}
.progress {
  background-color: white;
  border: 0.932868px solid #D3D8E3;
}
.progress-bar {
  background-color: green !important;
}


@media screen and (min-width: 1206px) {
  .content-detail .detail .header {
    color: white;
    margin-top: 3%;
  }
  .content-detail .detail .header .name {
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .content-detail .detail .header .back {
    position: absolute;
    top: 0px;
  }

  .site-item {
    margin: 50px 0px 50px 0px ;
  }
  .content-detail .detail .body {
  }
}
@media screen and (max-width: 1205px) {
  .content-detail .detail .header {
    color: black;
    height: 100px;
  }
  .content-detail .detail .header .name {
    margin-top: 0px;
  }
  .content-detail .detail .body {
    margin-top: 0px;
  }

  .share {
    margin-top: 20px;
    color: black;
  }
  .detail {
    width: 50% !important;
  }
  .button-actions {
    width: 30% !important;
  }
}

@media screen and (min-width: 601) {
  h1 {
    font-size: 2.6rem;
    flex: 1 1 1;
  }
}

@media screen and (max-width: 600) {
  h1 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1000px) {
  .br-mobile {
    display: none !important;
  }
  .item{
    width: 100% !important;
    margin-top: 0px !important;
    margin-bottom: 1em !important;
  }
  .style-value-text{    
    margin-left: 0.5em !important;
  }
  .style-value-text-mobile {
    margin-left: 0.5em !important;
  }
  .item-total-investido {
    margin-bottom: 0em !important;
  }
  .body{
    width: 100%;
  }
  .detail{
    width: 100% !important;
    margin-left: 0px !important;
  }
  .col2{
    margin-left: 0px !important;
  }
  .center{
    margin-left: 0px !important;
  }
  .button-actions{
    width: 100% !important;
    display: contents;
  }
  .buttons-mobile{
    width: 100% !important;
  }
  .invest{
    width: 100% !important;
    margin-top: 0.5em !important;
  }
  .cancel {
    width: 100% !important;
    margin-top: 0.5em !important;
  }
  .talk {
    width: 100% !important;
    margin-top: 0.5em !important;
  }
  .photo-detail{
    width: 100% !important;
  }
  .alert-time{
    height: auto;
  }
  .header {
    height: auto !important;
  }
  .left {
    width: 100% !important;
  }
}

h1 {
  margin-bottom: 40px;
  font-weight: 700;
  color: black;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
}

h3 {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
}

h1,
h2,
h3,
p {
  line-height: 1.3;
}

.scroll-hide p {
  scroll-snap-align: center;
  line-height: 1;
}
.scroll-hide {
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  margin-top: 60px;
}

.scroll-hide p {
  margin-bottom: 0;
}

.scroll-hide::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.por-tras-da-divvy {
  background-size: contain;
  background-repeat: repeat-x;
}
.cancel {
  background-color: white !important;
  color: #7C8391 !important;
  padding: 15px 40px 15px 40px;
}
.talk {
  padding: 15px 40px 15px 40px;
}
</style>