<template>
  <div class="more-info-content">
    <b-modal id="modalPhoto" title="Foto" ref="modalPhoto" centered size="xl" ok-only no-stacking>
      <div class="d-block text-center">
        <img class="img-modal" :src="'data:image/jpeg;base64,'+photoModal" />
      </div>
    </b-modal>

    <div class="tab-body">
      <b-card no-body>
        <b-tabs id="tabs" pills justified card align="center">
          <b-tab title="Acompanhe a Obra" active> 
            <ProjectStatus :project="project"/>
          </b-tab>
          <b-tab title="Acompanhe a cota" v-if="isInvestor === true">
            <FollowQuota :immobileId="project.idImmobile" :investmentId="investmentId"/>
          </b-tab>
          <b-tab title="Sobre o Projeto">
            <AboutProject :project="project"></AboutProject>
          </b-tab>
          </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
import { BCard, BTabs, BTab, BModal } from 'bootstrap-vue'
import AboutProject from './AboutProject.vue'
import ProjectStatus from '../../ProjectStatus.vue'
import FollowQuota from '../../FollowQuota.vue'
export default {

  name: 'FilterOpportunityComponent',
  components: {
    BCard,
    BTabs,
    BTab,
    BModal,
    AboutProject,
    ProjectStatus,
    FollowQuota
  },

  props: ['project', 'isInvestor', 'investmentId'],

  data() {
    return {
      photoModal: '',
    }
  },

  methods: {
    downloadPhoto(img) {
      this.photoModal = img
      this.$refs.modalPhoto.show()
    },
    downloadDoc(file, name) {
      const linkSource = `data:application/pdf;base64,${file}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = name
      downloadLink.click()
      downloadLink.remove()
    },
  },
}
</script>

<style>

  h1 {
    margin: 50px;
  }

  .modal-photo-content {

  }

  .imgModal {
    max-width: 1024px;
  }

  .flex-divider {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }

  .photos {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }

  .photo {
    margin: 5px;
    position: relative;
  }

  .photo .icon {
    margin: 10px;
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    cursor: pointer;
    transition: .4s;
  }

  .photo .icon:hover {
    color: orange;
  }

  .photo .img img {
    border-radius: 15px;
    max-width: 450px;
    max-height: 300px;
  }

  .documents {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .documents .document {
    width: 90px;
    height: 90px;
    border-radius: 7px;
    margin: 10px;
    align-content: center;
    text-align: center;
    padding: 15px 0px 15px 0px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    box-shadow: 0px 0px 10px rgba(0,0,0,.1);
  }
  .documents .document:hover {
    background-color: orange;
  }
  .documents .document .title{
    font-size: x-small;
  }

  .documents .item {
    cursor: pointer;
  }

  .documents .item:hover {
    color:orange;
  }

  .documents .icon {
    width: 50px;
    height: 50px;
    margin: 5px;
  }

  .pdf {
    border-radius: 15px;
    width: 100%;
    height: 800px;
  }

  .photo .blue-print {
    border-radius: 15px;
    max-width: 950px;
  }

  .more-info-content {
    margin-top: 10px;
    width: 100%;
    border-top: 1px solid #D9D9D9;
  }

  .more-info-content  h1 {
    text-align: center;
    font-size: xx-large;
    font-weight: 800;
    margin: 30px 0px 50px 0px;
  }

  .tab-body {
    width: 100%;
  }

  #tabs .nav-pills.nav-justified .nav-link.active {
    background-color: #555C69;
    color: white;
    box-shadow: none;
  }
  .img-modal{
    width: 100% !important;
  }
  @media screen and (max-width: 1000px) {
    .headers{
      display: grid !important;
    }
    .titles{
      text-align: left;
    }
    .titles-values {
      text-align: left;
    }
    .city{
      margin-left: 0 !important;
    }
    .constructor {
      margin-left: 0 !important;
    }

    .card-body{
      padding: 0 !important;
      margin-left: 0.5em !important;
      margin-right: 0.5em !important;
    }
  }

</style>
