<template>
  <div class="div-principal-follow-cota">
    <div class="dados-principal">
      <div class="dados">
        <div class="title-sections-details">Dados</div>
        <div class="box-left">
            <div class="left">
              <div class="title-details">Número de Cotistas<br><span class="value-details">{{details.investorsCount}}</span></div>
              <div class="title-details margin-top">Total Investido<br><span class="value-details">{{formatCurrencyPrice(details.investedValue)}}</span></div>
            </div>
            <div class="left left-right">
              <div class="title-details">Total de Cotas Adquiridas<br><span class="value-details">{{details.acquiredQuotas}}</span></div>
              <div class="title-details margin-top">Valorização<br><span class="value-details">{{details.valorizationPercent}}%</span></div>
            </div>
        </div>
      </div>      
      <div id="box-historic" class="box-historic">
        <div class="title-details title-history">Histórico</div>
        <div v-for="inv in details.historyQuota" :key="inv" class="history">
          <div class="date-history mobile-font">{{formatDate(inv.date)}}</div>
          <div class="mobile-font name-history margin-left-history">{{inv.name}}</div>
          <div class="mobile-font total-history margin-left-history">{{formatCurrencyPrice(inv.totalValueAcquired)}}</div>
          <div class="mobile-font rentability margin-left-history">
            <div v-if="inv.rentability > 0" class="rentability-up">
              {{formatCurrencyPrice(inv.rentability)}}
              <b-icon style="margin-left: 20%" icon="caret-up-fill"/>
            </div>
            <div v-else-if="inv.rentability === 0" style="float: right">
              {{formatCurrencyPrice(inv.rentability)}}
            </div>
            <div v-else class="rentability-down">
              {{formatCurrencyPrice(inv.rentability * -1)}}
              <b-icon style="margin-left: 20%" icon="caret-down-fill"/>
            </div>
          </div>
        </div>
      </div>
    </div>
<br></br>
    <div class="">
      <div class="title-sections-details">Status </div>
      <div class="progress-header">
          <div class="title-details" style="min-width: 50%;">Venda de Cotas:</div>
          <div class="progress-value">{{details.quotaSalePercent}}%</div>
      </div>
      <div class="progress">
          <div id="progress_quotas_sale" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${details.quotaSalePercent}%;`"></div>
      </div>
      <div class="progress-header">
          <div class="title-details" style="min-width: 50%;">Valorização:</div>
          <div class="progress-value">{{details.valorizationPercent}}%</div>
      </div>
      <div class="progress">
          <div id="progress_quotas_valorization" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${details.valorizationPercent}%;`"></div>
      </div>
      <div class="progress-header">
          <div class="title-details" style="min-width: 50%;">Venda de Cotas:</div>
      </div>
      <div class="value-details">{{formatCurrencyPrice(details.totalValueSold)}}</div>
    </div>
    <Valorization :investmentId="investmentId"/>
  </div>
</template>

<script>
import { BCard, BProgress } from 'bootstrap-vue'
import formatter from '@/mixins/formatter'
import toast from '@/mixins/toast'
import InvestmentService from '@/services/Investment/InvestmentService'
import Valorization from '@/components/ValorizationImmobile.vue'

export default {
  components: {
    BCard,
    BProgress,
    Valorization
  },
  props: ['immobileId', 'investmentId'],
  mixins:[formatter, toast],
  data() {
    return {
      details: {}
    }
  },
  methods: {
    formatDate(dateString) {
      return formatter.formatDate(dateString)
    },

    async getDetails(){
      try {
        this.details = (await InvestmentService.getQuotaDetails(this.immobileId)).data.result;
      } catch(error) {
        this.msgError(error.response.data.error.message)
      }
    }
  },

  mounted(){
    this.getDetails()
  }
}
</script>
<style>
.box-historic {
  width: 60%;
  height: 15em;
  left: 757px;
  top: 1048px;
  border: 2px solid #D3D8E3;
  box-sizing: border-box;
  border-radius: 10.152px;
  padding-top: 2%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  overflow-y:scroll;
}
.left {
  float: left;
  margin-right: 2em;
}
.box-left {
  width: 100%;
  display: flex
}
.margin-left-history {
  margin-left:10%;
  width: 25%;
}
.title-details {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  color: #555C69;
}
.value-details {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  color: #A6ADBA;
  font-size: 1.5em;
}
.history {
  display: flex;
  font-family: 'Source Sans Pro';
  font-style: normal;
  height: 4em;
  margin-top: 0.5em;
  margin-left: 5%;
  margin-right: 5%;
}
.title-history {
  margin-bottom: 2em;
}
.date-history {
  background: #FFF7E8;
  border-radius: 5px;
  color: #F8AC18;
  height: 50%;  
  width: 20%;  
  border-radius: 10%;
  text-align: center;
}
.name-history {
  font-weight: 600;
  font-size: 1.2em;
  line-height: 23px;
  width: 25%;  
  color: #555C69;
}
.total-history {
  font-weight: 400;
  font-size: 1.2em;
  color: #A6ADBA;  
  width: 25%;  
}
.rentability {
  width: 25%;     
  float: right;
}
.title-sections-details {
  font-size: 1.6em;
  color: #555C69;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2em;
}
.status{
  margin-top: 2%;
  margin-left: 5%;
  padding: 5%;  
  box-shadow: 0 4px 24px 0 rgba(146, 146, 146, 0.1);
}
.progress-header {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
}

#progress_quotas_sale {
  background-color: #00CFE8;
}
#progress_quotas_valorization {
  background-color: #5BD69E;
}
.margin-top{
  margin-top: 2em;
}
#box-historic::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#box-historic::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
#box-historic::-webkit-scrollbar-thumb {
  background: #F8AC18;
  border-radius: 10px;
}
.dados-principal {
  display: flex;
}
.dados{
  width: 50%;
}
.rentability-up {
  color: #06b606; 
  display: flex; 
  width:50%; 
  float: right;
}
.rentability-down{
color: #d10303; 
display: flex; 
width:50%; 
float: right;
}
@media screen and (max-width: 1000px) {
  .div-principal-follow-cota{
    padding: 1%;
  }
  .left {
    margin-right: 0;
  }
  .left-right {
    margin-left: 10%;
  }
  .dados{
    width: 100% !important;
    width: 100% !important;
  }
  .dados-principal {
    display: contents;
  }
  .box-historic{
    width: 100% !important;
    margin-top: 1em;
  }
  .history {
    margin-left: 0;
  }
  .date-history {
    width: 25% !important
  }
  .margin-left-history {
    margin-left: 0;
  }
  .mobile-font {
    text-align: center !important;
    font-size: 1em !important;
  }
  .rentability-up {
    margin-left: 10%;
    width: 100%;
    float: initial;
    text-align: center !important;
  }
  .rentability-down{
    margin-left: 10%;
    width: 100%;
    float: initial;
    text-align: center !important;
  }
}
</style>