<template>
  <div>
    <div class="banner-city" :style="style">
      <b-row class="site-container">
        <b-col :inner-html.prop="text | format" class="banner-text">&nbsp;</b-col>
        <b-col>&nbsp;</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

const img = require('../../assets/images/banner-opportunity.png')

export default {
  components: {
    BRow,
    BCol,
  },

  filters: {
    format(value) {
      const color = '#F8AC18'
      return value.replace(/\{(.*)\}/g, `<span style="color:${color};font-size:2.8rem;">$1</span>`)
    },
  },

  props: {
    text: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      style: {
        backgroundImage: `url('${img}')`,
      },
    }
  },
}
</script>

<style scoped>
.banner-city {
  width: 100%;
  height: 360px;
  margin-top: -33px;
  background-size: cover;
  background-repeat: no-repeat;
  font-weight: 600;
  color: white;
}

@media screen and (min-width: 601px) {
  .banner-city {
    padding-top: 6rem;
  }
}

@media screen and (max-width: 600px) {
  .banner-city {
    padding-top: 3rem;
  }
}

@media screen and (min-width: 601px) {
  .banner-text {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 600px) {
  .banner-text {
    font-size: 1.8rem;
  }
}
</style>
