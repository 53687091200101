<template>
    <b-modal id="modalCancell" ref="modalCancell" centered size="sm" ok-only no-stacking>
      <div class="text-title">
        Você tem certeza que quer cancelar este investimento?
      </div>
      <div class="text-subtitle">
          Você pode solicitar o cancelamento do seu investimento a qualquer momento, no entanto, 
          não há garantia por parde da Divvy que o cancelamento será aprovado. Entendemos que 
          imprevistos acontecem e nossa equipe fará de tudo para atender ao seu pedido da melhor 
          maneira possível.
      </div>
      <div class="text-motive-label">Qual o movito do cancelamento?</div>
      <BFormTextarea v-model="request.reason"/>
      <b-form-group class="text-subtitle" label="Como Você quer ser contatado?" v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="request.contactType" :aria-describedby="ariaDescribedby" name="some-radios" value="1">Via Ligação</b-form-radio>
        <b-form-radio v-model="request.contactType" :aria-describedby="ariaDescribedby" name="some-radios" value="2">Contato por e-mail</b-form-radio>
        <b-form-radio v-model="request.contactType" :aria-describedby="ariaDescribedby" name="some-radios" value="3">Via Whatsapp</b-form-radio>
      </b-form-group>
      <b-button id="buttonRequesCancellation" @click="requestCancel">Solicitar Cancelamento</b-button>
    </b-modal>
</template>
<script>

import { BFormGroup, BFormRadio, BFormTextarea, BModal, BButton } from 'bootstrap-vue'
import toast from '@/mixins/toast'
import CancellationRequestsService from '@/services/Investment/CancellationRequestsService'

export default {
    components: {
        BModal,
        BFormGroup, 
        BFormRadio,
        BFormTextarea,
        BButton
    },
    props:['investmentId'],
    mixins:[toast],

    data(){
        return {
            request: {}
        }
    },
    

    methods: {
        show(){            
            this.$refs.modalCancell.show()
        },

        async requestCancel(){
            if(this.request.reason === undefined){
                this.showError('Informe um motivo')
                return
            }
            if(this.request.reason === undefined){
                this.showError('Informe um  meio de contato')
                return
            }
            try {
                this.request.investmentQuotaId = this.investmentId
                await CancellationRequestsService.createOrEdit(this.request)
                this.$refs.modalCancell.hide()
                this.msgSuccess('Cancelamento solicitado com sucesso')
            } catch(error) {
                this.msgError(error.response.data.error.message)
            }
        }
    },
}
</script>
<style lang="scss">
#modalCancell___BV_modal_footer_{
    display: none;
}
#buttonRequesCancellation{
    margin-top: 10%;
    width: 100%;
    background-color: black !important;
}
.text-title {
    font-size: 1.5em;
    font-weight: 800 !important;
}
.text-subtitle {
    margin-top: 5%;
}
.text-motive-label{
    margin-top: 15%;
    margin-bottom: 4%;
}
@media (max-width: 576px) {
    #modalCancell{
        padding-right: 0.5rem !important
    }
}
</style>