import Vue from 'vue'

const TalkSpecialistConfigService = {

  GetTaskSpecialistConfigForEdit() {
    return Vue.prototype.$http.get(`/api/services/app/TaskSpecialistConfigs/GetTaskSpecialistConfigForEdit`)
  },

  createOrEdit(request) {
    return Vue.prototype.$http.post(`/api/services/app/TaskSpecialistConfigs/CreateOrEdit`, request)
  },
}

export default TalkSpecialistConfigService
