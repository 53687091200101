<template>
  <div>
    <b-modal id="modalPhoto" title="Foto" ref="modalPhoto" centered size="xl" ok-only no-stacking>
      <div class="d-block text-center">
        <img class="imagem-modal" :src="'data:image/jpeg;base64,'+photoModal" />
      </div>
    </b-modal>
    <div class="title-sections">Status da Obra </div>
    <b-card>
      <div class="headers">
        <div class="immobile-name">{{project.name}}</div>
        <div class="city">
          <div class="titles">Cidade</div>
          <div class="titles-values">{{project.city}}/{{project.state}}</div>
        </div>
        <div class="constructor">
          <div class="titles">Construtora</div>
          <div class="titles-values">{{project.companyName}}</div>
        </div>
      </div>
      <hr/>
      <div class="progress-header">
          <div style="min-width: 50%;"><b>Inicio:</b> {{formatDate(project.startDate)}}</div>
          <div class="progress-value"><b>Entrega:</b> {{formatDate(project.realeseDate)}}</div>
      </div>
      <div class="progress progress_date">
          <div id="progress_date" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${project.percentRemainingDays}%;`"></div>
      </div>
      <br><br>
      <div class="progress-header">
          <div style="min-width: 50%;"><b>Fundação</b></div>
          <div class="progress-value">{{project.foundation}}%</div>
      </div>
      <div class="progress progress_foundation">
          <div id="progress_foundation" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${project.foundation}%;`"></div>
      </div>
      <br><br>
      <div class="progress-header">
          <div style="min-width: 50%;"><b>Alvenaria</b></div>
          <div class="progress-value">{{project.masonry}}%</div>
      </div>
      <div class="progress progress_masonry">
          <div id="progress_masonry" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${project.masonry}%;`"></div>
      </div>
      <br><br>
      <div class="progress-header">
          <div style="min-width: 50%;"><b>Estrutural</b></div>
          <div class="progress-value">{{project.landscaping}}%</div>
      </div>
      <div class="progress progress_landscaping">
          <div id="progress_landscaping" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${project.landscaping}%;`"></div>
      </div>
      <br><br>
      <div class="progress-header">
          <div style="min-width: 50%;"><b>Cronograma Geral</b></div>
          <div class="progress-value">{{project.generalSchedule}}%</div>
      </div>
      <div class="progress progress_general">
          <div id="progress_general" class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="`width: ${project.generalSchedule}%;`"></div>
      </div>
    </b-card>
    <div class="title-sections">Fotos da Obra</div>
    <b-card>
      <div class="photos">
        <div v-for="photo in projectPhotos" :key="photo.binaryObject.id" class="photo">
          <div class="icon" @click="downloadPhoto(photo.binaryObject.byte)"><b-icon-box-arrow-in-down /></div>
          <div>
            <div>
              <img class=".img-background" :src="'data:image/jpeg;base64,'+photo.binaryObject.byte">
              <div class="description-img">{{photo.binaryObject.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BProgress } from 'bootstrap-vue'
import formatter from '@/mixins/formatter'
import ProjectPhotosService from '@/services/Project/ProjectPhotosService'
export default {
  components: {
    BCard,
    BProgress
  },
  props: ['project'],
  mixins:[formatter],

  data() {
    return {
      photoModal: null, 
      projectPhotos: []
    }
  },

  methods: {
    formatDate(dateString) {
      return formatter.formatDate(dateString)
    },
    async getPhotos(){
      this.projectPhotos = (await ProjectPhotosService.getAll(this.project.projectId)).data.result.items
    },
    downloadPhoto(img) {
      this.photoModal = img
      this.$refs.modalPhoto.show()
    },
  },

  mounted() {
    this.getPhotos()
  }
}
</script>
<style>
.headers {
  display: flex;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-right: 5%;
}
.immobile-name {
  font-size: 3em;
  min-width: 30%;
}
.city {
  margin-left: 10%;
  min-width: 30%;
}
.constructor {
  margin-left: 5%;
  min-width: 30%;
}
.titles {
  margin-top: 2em;
  color: #555C69;
  text-align: right;
}
.titles-values {
  font-size: 24px;
  color: #A6ADBA;
  font-family: 'Source Sans Pro';
  font-style: normal;
  text-align: right;
}
.progress-header {
    display: flex;
    font-size: 1.5em;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #555C69;
    margin-bottom: 1em;
}
.progress-value {
  min-width: 50%; 
  text-align: right !important;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #7C8391;
}
.progress_date {
  height: 3em;
}
.progress {
  background-color: white;
  border: 0.932868px solid #D3D8E3;
}
#progress_date {
  background-color: #00CFE8;
}
#progress_foundation {
  background-color: #5BD69E;
}
#progress_landscaping {
 background-color: #EA5455;
}
.description-img {
  background: rgba(248, 172, 24, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 0px 0px 10px 10px;
  position: relative;
  height: 2em;
  top: -2em;
  padding-left: 5%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  color: #FFFFFF;
}
.title-sections {
  font-size: 1.5em;
  font-weight: 700; 
  color: #555C69;
}

@media  screen and (max-width:500px) {

  img {
  max-width: 100%;
  height: auto;
  }

}

.img-background {
  background: linear-gradient(0deg, #000000 -13.71%, rgba(0, 0, 0, 0) 107.45%) !important;

}
.icon {
  color: white !important;
}
.imagem-modal{
  width: 100% !important;
}
</style>