<template>
  <b-card bg-variant="warning" class="text-light p-2 d-orange-card" :style="'background-image:url(' + require('../assets/images/vector-1.png') + ');'">
    <b-card-text>
      <b-row>
        <b-col sm="4">
          <p style="font-size: 1.4rem; font-weight: 600; line-height: 1">Fique por dentro das novidades do mundo dos investimentos!</p>
          <p style="font-size: 0.9rem; line-height: 1">
            Não pare de aprender nunca, a DIVVY te ajuda com isso também. Nossa equipe traz novidades toda semana para você aprender a investir da maneira certa.
          </p>
        </b-col>
        <b-col class="mt-2" sm="8">
          <b-input-group size="lg">
            <b-input-group-prepend is-text>
              <b-icon icon="envelope" />
            </b-input-group-prepend>
            <b-form-input v-model="email" type="email" style="padding: 26px" placeholder="email@example.com.br" />
          </b-input-group>
          <b-button v-if="!winSM" variant="warning" style="position: absolute; right: 25px; margin-top: -45px; z-index: 10" @click="assinarNewsletter">Assinar Newsletter</b-button>
        </b-col>
      </b-row>
      <div v-if="winSM" class="p-1 mt-2 bg-white" style="border-radius: 6px; border: 1px solid #ccc; text-align: center">
        <b-button variant="warning" @click="assinarNewsletter">Assinar Newsletter</b-button>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BCardText, BIcon, BInputGroup, BInputGroupPrepend, BFormInput } from 'bootstrap-vue'
import NewsletterService from '@/services/NewsletterService'
import toast from '@/mixins/toast'
import mixin from '@/mixins'

export default {

  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BCardText,
    BIcon,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  mixins: [mixin, toast],
  props: ['data'],

  data() {
    return {
      date: new Date(),
      email: '',
    }
  },

  methods: {
    async assinarNewsletter(ev) {
      ev.preventDefault()
      try {
        this.loading = true
        const newData = {}
        newData.email = this.email
        await NewsletterService.setNewsletter(newData)
        this.msgSuccess('Salvo com sucesso')
        //  this.$emit('reload')
        this.loading = false
      } catch (e) {
        this.msgError(e.message)
      }
    },
  },
}
</script>

<style scoped>
.d-orange-card {
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 7rem;
  background-color: #f8ac18 !important;
}
</style>
