import Vue from 'vue'

const ImmobileMarketPriceService = {

  createOrEdit(item) {
    return Vue.prototype.$http.post('/api/services/app/ImmobileMarketPrices/CreateOrEdit', item)
  },
  delete(id) {
    return Vue.prototype.$http.delete('/api/services/app/ImmobileMarketPrices/Delete?Id='+id)
  },
  getAll(filters) {
    return Vue.prototype.$http.get('/api/services/app/ImmobileMarketPrices/GetAll?'+filters)
  },
  getHistory(investmentId) {
    return Vue.prototype.$http.get('/api/services/app/ImmobileMarketPrices/GetHistory?investmentId='+investmentId)
  },
  getHistoryImmobilesInvestor() {
    return Vue.prototype.$http.get('/api/services/app/ImmobileMarketPrices/GetHistoryImmobilesInvestor')
  },
  getImmobileDistribution() {
    return Vue.prototype.$http.get('/api/services/app/ImmobileMarketPrices/GetImmobileDistribution')
  }
}

export default ImmobileMarketPriceService
