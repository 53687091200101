<template>
  <div class="card-compare-investment-chart">
    <div class="title"><h3>Gráfico de Rentabilidade Comparada</h3></div>
    <line-chart :chart-data="datacollection" :styles="myStyles" :options="options"></line-chart>
  </div>
</template>

<script lang="ts">
import { } from 'bootstrap-vue'
import LineChart from '@/components/charts/Line'
import ImmobileMarketPriceService from '@/services/Investment/ImmobileMarketPriceService'

export default {
  name: 'CardValorizationInvestmentComponent',
  components: {
    LineChart,
  },

  props: [
    'investmentId',
  ],
  mounted() {
    this.fillData()
    this.renderChart(this.chartData, this.options)
  },

  computed: {
    myStyles() {
      return {
        height: '350px',
      }
    },
  },

  data() {
    return {
      datacollection: {},
      options: {
        title: {
          display: false,
        },
        pieceLabel: {
          mode: 'value',
          precision: 1,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          }],
        },
      },
    }
  },
  methods: {
    async fillData() {
      const { result } =  (await ImmobileMarketPriceService.getHistory(this.investmentId)).data

      this.datacollection = {
          labels: result.months,
          datasets: [
            {
              label: 'CDI',
              borderColor: '#666EE8',
              data: result.cdi,
              fill: false
            }, 
            {
              label: 'IGP-M',
              borderColor: '#FFE802',
              data: result.igpm,
              fill: false
            }, 
            {
              label: 'Cota',
              borderColor: '#FF4961',
              data: result.quota,
              fill: false
            }
          ]
        }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    back() {
      this.$emit('onBack')
    },
  },
}
</script>

<style>

    .card-compare-investment-chart {
      width: 100%;
      display: inline-block;
      margin-top: 8%;
      margin-bottom: 3em;
    }

    .card-compare-investment-chart .title {
      float: left;
      margin-bottom: 20px;
    }

    .card-compare-investment-chart .title h3 {
     font-weight: 800 !important;
    }

    .card-compare-investment-chart .back-action {
      float: right;
      font-size: 0.9rem;
      font-weight: 800;
      cursor: pointer;
    }

    .card-compare-investment-chart .back-action:hover {
      color: orange;
    }

    @media screen and (max-width:500px) {

      .card-compare-investment-chart {
      width: 100%;
      display: inline-block;
      padding-bottom: 80px;
      }
    }
</style>
