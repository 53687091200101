import Vue from 'vue'

const OpportunityService = {
  // BINARY

  getResume(id) {
    return Vue.prototype.$http.get(`/api/services/app/OpportunityService/GetResume?immobileId=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },
  getDetails(id) {
    return Vue.prototype.$http.get(`/api/services/app/OpportunityService/GetDetails?immobileId=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },
  getDetailsByInvestment(id) {
    return Vue.prototype.$http.get(`/api/services/app/OpportunityService/GetDetailsByInvestment?investmentId=${id}`,
      this.reset, {
        timeout: 300000,
      })
  },
}

export default OpportunityService
